import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"
import about from "../images/about/about-us.jpg"
// import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const AboutUs = props => {
  return (
    <Layout>
      <Head
        title="About"
        description="Okay. We admit it. We're a bit different. Actually, we're
        REALLY different. We think it's safe to say that
        ThrowThings.com is like no other company that you've ever
        worked with."
      />

      <Container>
        {/* <!-- about section start --> */}
        <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-section">
                  {/* <Img fluid={props.data.imageOne.childImageSharp.gatsbyImageData} alt="Computer and Coffee" /> */}
                  <img
                    src={about}
                    className="img-fluid lazyload"
                    alt="Computer and Coffee"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <h4>About ThrowThings.com</h4>
                <p>
                  Okay. We admit it. We're a bit different. Actually, we're
                  REALLY different. We think it's safe to say that
                  ThrowThings.com is like no other company that you've ever
                  worked with.
                </p>
                <p>
                  ThrowThings.com was established in April, 2001. Over the next
                  years we became one of the fastest growing companies in our
                  region. The company is built on a unique business model that
                  combines a broad concept (ThrowThings) and a range of
                  departments.
                </p>
                <p>
                  At ThrowThings.com you'll find toys and games in the “Things
                  To Throw” department, ventriloquist dummies under “Throw Your
                  Voice”, and customized promotional products under “Throw Your
                  Name Around”. Other departments include “Throw Backs”, "Throw
                  A Party", “ThrowThings Things”, and, of course, “Throw Up”.
                  You can even “Throw Your Money Away”. (For $3.50 plus s/h get
                  a certificate showing you threw your money away.)
                </p>
                <p>
                  A visit to ThrowThings.com brings a smile but as a business it
                  is no joke. Our great combination of service, pricing, and
                  ideas has made us one of the top promotional products
                  companies in the country. We have provided promotional
                  products for Google, Boeing, General Mills, Georgia Pacific,
                  CBS, Wells Fargo, Symantec, Subaru of America and many others.
                  Orders have shipped to 44 countries and counting. The top
                  seller of ventriloquist dummies in the world, we have provided
                  dummies for commercials, major motion pictures like "Dead
                  Silence", Broadway promotions for the Tony Award winning "Jay
                  Johnson" The Two And Only" and national television shows
                  including the CBS hit "Two And A Half Men" and "Saturday Night
                  Live".
                </p>
                <p>
                  ThrowThings.com is proud to have received the Weatherhead 100
                  Upstart award for three consecutive years, to have been an NEO
                  Success Award winner, to have won the COSE Ten Under 10 award,
                  to have been an American Business Awards finalist in the Best
                  Overall Company and Most Innovative Company categories, and to
                  have received RatePoint’s Business Excellence Award for its
                  top rated customer satisfaction. The greatest recognition that
                  we receive though is that customers keep returning and
                  referring their associates.
                </p>
                <p>
                  ThrowThings.com has it's physical offices in Ravenna, OH, but
                  we're as close to you as the nearest computer with internet
                  access or telephone. We are proud members in good standing of
                  ASI and COSE.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- about section end --> */}

        {/* <!--Testimonial start--> */}
        {/* <section className="testimonial small-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="slide-2 testimonial-slider no-arrow">
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/avtar.jpg")}
                          alt="#"
                        />
                        <h5>Mark Jecno</h5>
                        <h6>Designer</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          you how all this mistaken idea of denouncing pleasure
                          and praising pain was born and I will give you a
                          complete account of the system, and expound the actual
                          teachings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img src={require("../assets/images/2.jpg")} alt="#" />
                        <h5>Mark Jecno</h5>
                        <h6>Designer</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          you how all this mistaken idea of denouncing pleasure
                          and praising pain was born and I will give you a
                          complete account of the system, and expound the actual
                          teachings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/avtar.jpg")}
                          alt="#"
                        />
                        <h5>Mark Jecno</h5>
                        <h6>Designer</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          you how all this mistaken idea of denouncing pleasure
                          and praising pain was born and I will give you a
                          complete account of the system, and expound the actual
                          teachings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/avtar.jpg")}
                          alt="#"
                        />
                        <h5>Mark Jecno</h5>
                        <h6>Designer</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          you how all this mistaken idea of denouncing pleasure
                          and praising pain was born and I will give you a
                          complete account of the system, and expound the actual
                          teachings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/avtar.jpg")}
                          alt="#"
                        />
                        <h5>Mark Jecno</h5>
                        <h6>Designer</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          you how all this mistaken idea of denouncing pleasure
                          and praising pain was born and I will give you a
                          complete account of the system, and expound the actual
                          teachings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/avtar.jpg")}
                          alt="#"
                        />
                        <h5>Mark Jecno</h5>
                        <h6>Designer</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          you how all this mistaken idea of denouncing pleasure
                          and praising pain was born and I will give you a
                          complete account of the system, and expound the actual
                          teachings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!--Testimonial ends--> */}
      </Container>
    </Layout>
  );
}

export default AboutUs

export const pageQuery = graphql`{
  imageOne: file(relativePath: {eq: "about/about-us.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`;
